import * as React from "react"
import "./layout.css"
const Layout = ({ children }) => {
  return (
    <>
      <div
        style={{
          margin: `var(--space-5)`,
          maxWidth: `var(--size-content)`,
          paddingLeft: `var(--size-gutter)`,
        }}
      >
        <main>{children}</main>
      </div>
    </>
  )
}
export default Layout
