import * as React from "react"
import Layout from "../components/404"
import { Link } from "gatsby"
import Seo from "../components/seo"
const NotFoundPage = () => (
  <Layout>
    <h1>Ошибка 404. Страница не найдена!</h1>
    <p>Для перехода на главную страницу Dosugcz-ru.ru
      <br></br><br></br>
      <Link to="/">Нажмите здесь</Link></p>
  </Layout>
)

export const Head = () => (
  <Seo title="Ошибка 404. Страница не найдена!"
    description="DOSUGCZ-RU: Ошибка 404. Страница не найдена!">
    <meta name="robots" content="noindex,nofollow"/>
  </Seo>
)
export default NotFoundPage
